import React from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import CodeBlock from './code-renderer';

import styles from '../../styles/common/mdx-renderer.module.css';

function CustomMDXRenderer({ children }) {
  const components = {
    pre: CodeBlock
  };

  return (
    <div className={styles.mdxWrapper}>
      <MDXProvider components={components}>
        <MDXRenderer>{children}</MDXRenderer>
      </MDXProvider>
    </div>
  );
}

export default CustomMDXRenderer;
