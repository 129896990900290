import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import { LocalizedLink as Link } from 'gatsby-theme-i18n';

import Layout from '../../components/common/layout';
import SEO from '../common/seo';
import MDXRenderer from '../common/mdx-renderer';
import BookRating from './book-rating';

import styles from '../../styles/books/book-template.module.css';

import localized from '../../../i18n/localized.json';

function Book({ data, pageContext }) {
  const { mdx: { frontmatter, body }, unknownCover: { fixed } } = data;
  const { locale } = pageContext;

  const bookShelfLink = () => (
    <Link to={`/books/${frontmatter.shelf}`}>
      {localized[locale].shelf[frontmatter.shelf]}
    </Link>
  );

  const pageDetails = {
    pageTitle: `${frontmatter.authors.join(', ')} "${frontmatter.title}" | wiewior.dev`,
    seoTitle: `${frontmatter.authors.join(', ')} "${frontmatter.title}" | wiewior.dev`,
    seoDescription: `"${frontmatter.title}" ${frontmatter.authors.join(', ')}.`,
    seoType: 'article',
    seoImage: `/images/books/${frontmatter.slug}-${locale}-share.png`
  };

  return (
    <Layout>
      <div className={styles.bookWrapper}>
        <SEO pageDetails={pageDetails} />
        <div className={styles.metaWrapper}>
          <div className={styles.bookPageCover}>
            <Img fixed={frontmatter.cover?.childImageSharp?.fixed || fixed} />
            <BookRating rating={frontmatter.rating} />
          </div>
          <div className={styles.bookDetails}>
            <div className={styles.metaHeader}>
              <h1>{frontmatter.title}</h1>
              <div className={styles.metaAuthors}>{frontmatter.authors.join(', ')}</div>
            </div>
            <div className={styles.metaDetails}>
              <div className={styles.metaLine}>{localized[locale].book.publisher}: {frontmatter.publisher}</div>
              <div className={styles.metaLine}>{localized[locale].book.year}: {frontmatter.year}</div>
              <div className={styles.metaLine}>{localized[locale].book.pages}: {frontmatter.pages}</div>
              <div className={styles.metaLine}>{localized[locale].book.language}: <span className={`flag-${frontmatter.language} flag-outbound`} /></div>
              <div className={styles.metaLine}>{localized[locale].book.format}: {frontmatter.format}</div>
              <div className={styles.metaLine}>{localized[locale].shelf.onShelf}: {bookShelfLink()}</div>
              <div className={styles.metaLine}>{localized[locale].book.categories}: {frontmatter.categories.join(', ')}</div>
              <div className={styles.metaLine}>{localized[locale].book.startDate}: {frontmatter.shelf !== 'to-read' ? frontmatter.startDate : '—'}</div>
              <div className={styles.metaLine}>{localized[locale].book.endDate}: {frontmatter.shelf === 'read' ? frontmatter.endDate : '—'}</div>
            </div>
          </div>
        </div>
        <div className={styles.divider} />
        { body ? <MDXRenderer>{body}</MDXRenderer> : 'No review yet' }
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($locale: String!, $slug: String!, $dateFormat: String!) {
    mdx(
      fields: { locale: { eq: $locale } }
      frontmatter: { slug: { eq: $slug } }
    ) {
      frontmatter {
        authors
        title
        categories
        slug
        publisher
        year
        language
        pages
        format
        shelf
        startDate(formatString: $dateFormat)
        endDate(formatString: $dateFormat)
        rating
        cover {
          childImageSharp {
            fixed(jpegQuality: 100, width: 280) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
      body
    }
    unknownCover: imageSharp(fixed: {originalName: {eq: "unknown-cover.png"}}) {
      fixed(width: 280, pngQuality: 100) {
        ...GatsbyImageSharpFixed
      }
    }
  }
`;

export default Book;
